import { createStore } from 'redux'

const initialState = {
  sidebarUnfoldable: true,
  sidebarShow: true,
  asideShow: false,
  theme: localStorage.getItem('GR-THEME') ? localStorage.getItem('GR-THEME') : 'dark',
  //theme: 'dark',
  tokenExpired: false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
